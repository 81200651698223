/** Main imports. */
@import '../vendor/normalize/normalize.css';
@import '../vendor/bootstrap/bootstrap-grid.min.css';
@import '../vendor/owl/owl.css';

/** Local imports. */
@import 'reset';
@import 'fonts';
@import 'vars';


/* Body */
body {
	font-family: font(main);
	overflow-x: hidden;
}

/** Local imports. */
@import 'elements/elements';
@import 'elements/cta';
@import 'elements/header';
@import 'elements/footer';
@import 'elements/programacao';
@import 'elements/hero-pages';

/* Pages */
@import 'pages/homepage'; 
@import 'pages/cardapio'; 
@import 'pages/eventos';
@import 'pages/sobre'; 
@import 'pages/contato'; 
