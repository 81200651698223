// Programação
#programacao {
	width: 100%;
	margin: 0;
	padding: 150px 0 50px;
	background-color: color(yellow, base);

	// Mobile
	@media (max-width: 1024px) {
		padding: 50px 0;
	}

	// Headline ( Title )
	.headline {
		width: 100%;
		margin: 0;
		padding: 0;
		text-align: center;
		opacity: 0;
		visibility: hidden;
		transform: translateY(15px);
		transition: all 700ms;
		
		// & effect appear
		&.appear {
			opacity: 1;
			visibility: visible;
			transform: translateY(0px);
		}
		
		// Title
		h2 {
			font-size: 50px;
			font-weight: normal;
			font-family: font(title);
			color: color(red);
			margin: 0;
			padding: 0;
			display: block;
			
			// Mobile
			@media (max-width: 1024px) {
				font-size: 40px;
			}
			@media (max-width: 767px) {
				font-size: 25px;
			}
		}
	}

	// Navigation
	.navigation	{
		width: 100%;
		margin: 10px 0 0;
		padding: 0;
		text-align: center;
		position: relative;
		opacity: 0;
		visibility: hidden;
		transform: translateY(15px);
		transition: all 700ms;

		// Mobile
		@media (max-width: 1023px) {
			width: 600px;
			margin: 40px auto 0;
		}
		@media (max-width: 767px) {
			width: 300px;
			margin: 40px auto 0;
		}
		@media (max-width: 425px) {
			width: 230px;
		}
		
		// & effect appear
		&.appear {
			opacity: 1;
			visibility: visible;
			transform: translateY(0px);
		}
		
		.owl-item {
			outline: none;
			cursor: pointer;

			// Item
			.item {
				outline: none;
				// Number
				.number {
					font-size: 45px;
					font-weight: normal;
					font-family: font(open);
					color: color(red);
					margin: 0;
					padding: 0;
					transition: all 500ms;
				}

				// Day
				.day {
					font-size: 14px;
					font-weight: normal;
					font-family: font(open);
					color: color(red);
					margin: 5px 0 0;
					padding: 0;
					transition: all 500ms;
				}

				&.active,
				&:hover {
					.number,
					.day {
						color: color(yellow, dark);
					}
				}
			}

			// Item active
		}

		// Arrows
		.arrows {
			position: absolute;
			top: 50%;
			left: -35px;
			right: -35px;
			z-index: 3;
			transform: translateY(-50%);
			pointer-events: none;
			.flex-wrapper {
				display: flex;
				align-items: center;
				justify-content: space-between;
				.arrow {
					pointer-events: initial;
					cursor: pointer;
					font-size: 44px;
					font-family: font(open);
					font-weight: normal;
					color: color(yellow, dark);
				}
			}
		}
	}

	// Tabs
	.tabs {
		width: 100%;
		margin: 50px 0 0;
		padding: 0;
		outline: none;
		opacity: 0;
		visibility: hidden;
		transform: translateY(15px);
		transition: all 500ms;
		
		// & effect appear
		&.appear {
			opacity: 1;
			visibility: visible;
			transform: translateY(0px);
		}

		.tab-content {
			width: 100%;
			margin: 0;
			padding: 0;
			opacity: 0;
			visibility: hidden;
			outline: none;
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			transition: all 500ms;
			transform: translateY(15px);
			
			// Tab active
			&.active {
				transform: translateY(0px);
				opacity: 1;
				visibility: visible;
				transition-delay: 550ms;
			}

			// Content 
			.content {
				width: 100%;
				margin: 0;
				padding: 0;
				ul {
					width: 495px;
					margin: 0 auto;

					// Mobile
					@media (max-width: 767px) {
						width: 100%;
						max-width: 495px;
					}

					li {
						display: block;
						text-align: center;
						padding: 10px 0;
						border-bottom: 1px solid color(yellow, dark);

						&:last-child {
							border: none;
						}
						
						// Title
						.title {
							width: 100%;
							font-size: 23px;
							font-weight: normal;
							color: color(red);
							margin: 0 0 3px;
							padding: 0;
							display: block;

							@media (max-width: 425px) {
								font-size: 18px;
							}
						}

						// Subtitle
						.subtitle {
							font-size: 12px;
							font-family: font(open);
							font-weight: normal;
							color: color(red);
							margin: 0;
							padding: 0;
						}
					}
				}
			}

		}
	}
}