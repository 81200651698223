#main-footer {
	width: 100%;
	margin: 0;
	padding: 50px 0 0;
	background-color: #471b0e;
	position: relative;
	z-index: 1;

	// Title
	.title {
		width: 100%;
		margin: 0;
		padding: 0;
		border-bottom: 1px solid color(yellow, base);
		text-align: center;
		display: block;
		h3 {
			font-size: 25px;
			font-weight: normal;
			font-family: font(title);
			color: color(yellow, base);
			background-color: #471b0e;
			margin: 0;
			padding: 0 20px;
			display: inline-block;
			transform: translateY(10px);
			position: relative;
		}
	}

	// Horarios
	.horarios {
		width: 100%;
		margin: 0;
		padding: 0;
		table {
			width: 100%;
			margin: 15px 0 0;
			padding: 0;
			border-bottom: 1px solid color(yellow, base);
			span {
				display: block;
				text-align: center;
				font-size: 14px;
				font-weight: 300;
				color: color(yellow, base);
				margin: 0;
				padding: 0 0 15px;
			}
			td {
				&:first-child {
					span {
						text-align: left;
					}
				}
				&:last-child {
					span {
						text-align: right;
					}
				}
			}
		}
	}

	// Content
	.content {
		width: 290px;
		margin: 0 auto;
		padding: 0;
		text-align: center;

		// Mobile
		@media (max-width: 768px) {
			margin: 80px auto;
		}
		
		// Logo 
		.logo {
			margin-bottom: 20px;
		}

		// Navigation
		.nav {
			width: 100%;
			margin: 0;
			padding: 0;
			ul {
				width: 100%;
				margin: 0;
				padding: 0;
				li {
					display: inline-block;
					position: relative;
					margin-bottom: 6px;

					// After ( | )
					&::after {
						content: ' | ';
						position: relative;
						font-size: 16px;
						font-weight: normal;
						color: color(dark);
						margin: 0 15px;
						padding: 0;
						display: inline-block;
					}

					&:last-child,
					&:nth-child(3) {
						&::after {
							content: none
						}
					}

					// Text
					a {
						font-size: 14px;
						font-weight: normal;
						color: color(yellow, dark);
						margin: 0;
						padding: 0;
						display: inline-block;
						transition: all 500ms;
						&:hover {
							color: color(yellow, base);
						}
					}
				}
			}
		}

		// Informations
		.informations {
			width: 100%;
			margin: 35px 0 0;
			padding: 0;
			p {
				font-size: 14px;
				font-weight: normal;
				color: color(yellow, dark);
				margin: 0 0 5px;
				padding: 0;
				line-height: 1.2;
				&:last-child {
					margin: 0;
				}
			}
		}
	}

	// Social
	.social {
		width: 100%;
		margin: 0 0 55px;
		padding: 0;
		ul {
			width: 100%;
			margin: 25px 0 0;
			padding: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			li {
				display: block;
				margin: 0 40px 0 0;
				padding: 0;
				&:last-child {
					margin: 0;
				}
				a {
					font-size: 15px;
					font-weight: normal;
					color: color(yellow, base);
					margin: 0;
					padding: 0;
					display: flex;
					align-items: center;
				}
				span {
					display: inline-block;
					margin: 0 5px 0 0;
					padding: 0;
					width: 20px;
					height: 20px;
					background-color: color(yellow, base);
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 12px;
					transition: all 500ms;
					svg {
						color: #471b0e;
						transition: all 500ms;
					}
				}

				&:hover {
					span {
						background-color: #471b0e;
						svg {
							color: color(yellow, base)
						}
					}
				}
			}
		}
	}

	// Delivery
	.delivery {
		width: 100%;
		margin: 0 0 0;
		padding: 0;
		.images {
			width: 100%;
			margin: 25px 0 0;
			padding: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			img {
				margin-right: 30px;
				&:last-child {
					margin: 0;
				}
			}
		}
	}

	// Copyright
	.copyright {
		width: 100%;
		margin: 40px 0 0;
		padding: 20px 0;
		background-color: black;

		// Flex wrapper
		.flex-wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;

			// Text
			span {
				font-size: 11px;
				font-weight: normal;
				color: color(yellow, dark);
				margin: 0;
				padding: 0;
				display: block;
			}

			// Logo
			.assinatura {
				margin: 0;
				padding: 0;
			}
		}

	}
}