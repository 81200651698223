.page-home {
	width: 100%;
	max-width: 100%;
	overflow: hidden;
	margin: 0;
	padding: 0;

	@media (min-width: 768px) and (max-height: 665px) {
		position: relative;
	}

	// Hero sectin
	#hero {
		width: 100%;
		height: 100vh;
		min-height: 715px;
		margin: 0;
		padding: 0;
		background-color: black;
		position: relative;
			
		@media (max-width: 1024px) {
			min-height: 650px;
		}
		@media (max-width: 768px) and (max-height: 555px) {
			min-height: 540px;
		}

		// Slider 
		.slider {
			width: 100%;
			margin: 0;
			padding: 0;

			// Item
			.item {
				width: 100%;
				height: 100vh;
				min-height: 715px;
				position: relative;
				margin: 0;
				padding: 0;

				@media (max-width: 1024px) {
					min-height: 650px;
				}
				@media (max-width: 768px) and (max-height: 555px) {
					min-height: 540px;
				}

				// Image
				.img {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					z-index: 1;
					background-size: cover;
					background-position: center center;
					background-repeat: no-repeat;
				}

				// Content 
				.flex-wrapper {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					z-index: 2;
					display: flex;
					align-items: center;
					justify-content: center;
					.content {
						margin: 0;
						padding: 0;
						max-width: 550px;

						// Mobile
						@media (max-width: 1140px) {
							max-width: 480px
						}
						@media (max-width: 768px) {
							max-width: 380px
						}
						@media (max-width: 767px) {
							width: 60%;
						}

						img {
							max-width: 100%;
						}
					}
				}
			}
		}

		// Arrows
		.arrows {
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			z-index: 3;
			transform: translateY(-50%);
			pointer-events: none;
			.flex-wrapper {
				display: flex;
				align-items: center;
				justify-content: space-between;
				.arrow {
					pointer-events: initial;
					cursor: pointer;

					&-right {
						transform: rotate(180deg);
					}
				}
			}
		}

		// Dots
		.owl-dots {
			position: absolute;
			bottom: 110px;
			left: 0;
			right: 0;
			text-align: center;
			
			// Mobile
			@media (max-width: 1140px) {
				bottom: 30px;
			}

			.owl-dot {
				width: 20px;
				height: 20px;
				background-color: white;
				border-radius: 50%;
				overflow: hidden;
				display: inline-block;
				transition: all 500ms;
				margin-right: 10px;
				&:last-child {
					margin: 0;
				}

				&.active,
				&:hover {
					background-color: color(yellow, base);
				}
			}
		}
	}

	// Agenda
	#agenda {
		width: 100%;
		margin: 0;
		padding: 80px 0 290px;
		background-color: color(red);
		position: relative;
		background-image: url('../images/bg-agenda.png');
		background-position: top center;
		background-repeat: no-repeat;

		@media (max-width: 767px) {
			padding-bottom: 200px;
		}
		
		
		// Headline ( Title and paragraph )
		.headline {
			width: 100%;
			margin: 0;
			padding: 0;
			text-align: center;
			
			// Title
			h2 {
				font-size: 74px;
				font-weight: normal;
				font-family: font(title);
				color: color(yellow, base);
				margin: 0;
				padding: 0;
				display: block;
				opacity: 0;
				visibility: hidden;
				transform: translateY(15px);
				transition: all 500ms;

				// Mobile
				@media (max-width: 1140px) {
					font-size: 60px;
				}
				@media (max-width: 768px) {
					font-size: 45px;
				}
				@media (max-width: 767px) {
					font-size: 25px;
					line-height: 1.3;
				}
				
				// & effect appear
				&.appear {
					opacity: 1;
					visibility: visible;
					transform: translateY(0);
				}
			}
			
			// Content
			.content {
				width: 100%;
				margin: 15px 0 20px;
				padding: 0 40px;
				opacity: 0;
				visibility: hidden;
				transform: translateY(15px);
				transition: all 500ms;

				@media (max-width: 767px) {
					padding: 0;
				}
				
				// & effect appear
				&.appear {
					opacity: 1;
					visibility: visible;
					transform: translateY(0);
				}
				
				// Paragraph
				p {
					font-size: 16px;
					font-weight: normal;
					color: color(yellow, light);
					margin: 0;
					padding: 0;
					line-height: 1.5;
				}
			}

		}

		// Posts
		.posts {
			width: 100%;
			margin: 0;
			padding: 0;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			opacity: 0;
			visibility: hidden;
			transform: translateY(calc(50% + 15px));
			transition: all 1500ms;
			
			// & effect appear
			&.appear {
				opacity: 1;
				visibility: visible;
				transform: translateY(50%);
			}
				
			// Owl item
			.owl-item {
				padding-bottom: 50px;
			}

			// Item
			.item {
				width: 100%;
				max-width: 175px;
				margin: 0 auto;
				height: 470px;
				position: relative;
				
				@media (max-width: 767px) {
					height: 320px;
				}

				// Shadow
				&::after {
					content: ' ';
					position: absolute;
					top: 0;
					right: 20px;
					bottom: -15px;
					left: 20px;
					background-color: color(black);
					z-index: -1;
					filter: blur(7px);
					opacity: 0.5;
				}

				// Full link
				.full {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					z-index: 3;
				}

				// Background
				.bg {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					z-index: 1;
					overflow: hidden;
					// Image
					.img {
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						z-index: 1;
						background-size: cover;
						background-position: center center;
						background-repeat: no-repeat;
						transition: all 700ms;
						transform: scale(1);
					}
				}

				
				// Wrapper
				.wrapper {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					z-index: 2;
					display: flex;
					align-items: center;
					
					// Content
					.content {
						width: 100%;
						margin: 0;
						padding: 0 15px;
						text-align: center;

						// Logo
						.logo {
							width: 100%;
							margin: 0 0 25px;
							padding: 0;
							img {
								width: auto;
								max-width: 100%;
								margin: 0 auto;
							}
						}
						
						// Title
						.title {
							width: 100%;
							margin: 0;
							padding: 0;
							h3 {
								font-size: 22px;
								font-weight: normal;
								color: color(yellow, light);
								margin: 0;
								padding: 0;
								text-transform: uppercase;
							}

							&::after {
								content: ' ';
								position: relative;
								width: 75px;
								height: 2px;
								background-color: color(yellow, light);
								display: inline-block;
								margin: 0 auto;
								padding: 0;
								transition: all 700ms;
							}
						}

						// Date
						.date {
							width: 100%;
							margin: 10px 0 0;
							padding: 0;
							span {
								font-size: 13px;
								font-weight: normal;
								font-family: font(open);
								margin: 0;
								padding: 0;
								color: color(yellow, light);
							}
						}
					}
				}

				// Hover
				&:hover {
					.img {
						transform: scale(1.05);
					}

					.wrapper {
						.title {
							&::after {
								width: 90px;
							}
						}
					}
				}
			}

			// Arrows
			.arrows {
				position: absolute;
				top: 50%;
				left: -50px;
				right: -50px;
				z-index: 3;
				transform: translateY(-50%);
				pointer-events: none;

				@media (max-width: 767px) {
					left: -10px;
					right: -10px;
				}

				.flex-wrapper {
					display: flex;
					align-items: center;
					justify-content: space-between;
					.arrow {
						pointer-events: initial;
						cursor: pointer;

						&-right {
							transform: rotate(180deg);
						}
					}
				}
			}
		}
	}

	// Especialista
	#especialista {
		width: 100%;
		margin: 0;
		padding: 420px 0 175px;
		background-color: color(bg);

		// Mobile
		@media (max-width: 767px) {
			padding: 200px 0 50px;
			text-align: center;
		}

		// Title
		h2 {
			font-size: 74px;
			font-weight: normal;
			font-family: font(title);
			color: color(red);
			margin: 0;
			padding: 0;
			display: block;
			opacity: 0;
			visibility: hidden;
			transform: translateY(15px);
			transition: all 500ms;

			// Mobile
			@media (max-width: 1140px) {
				font-size: 60px;
			}
			@media (max-width: 768px) {
				font-size: 45px;
			}
			@media (max-width: 767px) {
				font-size: 25px;
			}
			
			// & effect appear
			&.appear {
				opacity: 1;
				visibility: visible;
				transform: translateY(0);
			}
		}
		
		// Content
		.content {
			width: 100%;
			margin: 20px 0 0;
			padding: 0;
			opacity: 0;
			visibility: hidden;
			transform: translateY(15px);
			transition: all 500ms;

			// Mobile
			@media (max-width: 767px) {
				margin-bottom: 170px;
			}
			
			// & effect appear
			&.appear {
				opacity: 1;
				visibility: visible;
				transform: translateY(0);
			}

			// Paragraph
			p {
				font-size: 16px;
				font-weight: normal;
				color: color(black);
				margin: 0;
				padding: 0;
				line-height: 1.5;
			}
		}

		// Image
		.img {
			width: 100%;
			margin: 0;
			padding: 0;
			position: relative;
			img {
				max-width: 100%;
			}
		}
	}

	// Parrila
	#parrila {
		width: 100%;
		margin: 0;
		padding: 130px 0 30px;
		background-color: #5f1e11;
		position: relative;

		// Container
		.container {
			position: relative;
		}

		// Image
		.img {
			position: absolute;
			top: 20px;
			left: 0;
			right: 0;
			opacity: 0;
			visibility: hidden;
			transition: all 1000ms;

			// Mobile
			@media (max-width: 1140px) {
				left: -150px;
				right: 150px;
			}
			@media (max-width: 767px) {
				display: none;
			}
			
			// & effect appear
			&.appear {
				opacity: 1;
				visibility: visible;
			}

			// Img
			img {
				transform: translateX(-200px);
			}
		}

		// Bars 
		.bars {
			position: absolute;
			top: -70px;
			right: 0;
			pointer-events: none;

			// Mobile
			@media (max-width: 767px) {
				right: 10px;
			}


			.bar {
				display: inline-block;
				width: 5px;
				height: 40px;
				background-color: #4c170d;
				border-radius: 10px;
				transform: rotate(45deg) translateY(25px);
				margin-right: 15px;
				opacity: 0;
				visibility: hidden;
				transition: all 400ms;

				&:nth-child(2n + 0) {
					// Mobile
					@media (max-width: 768px) {
						display: none;
					}

				}

				&:nth-child(3n + 0) {

					// Mobile
					@media (max-width: 767px) {
						display: none;
					}

				}
				
				// & effect appear
				&.appear {
					opacity: 1;
					visibility: visible;
					transform: rotate(45deg) translateY(0px);
				}
			}
		}

		// Title
		h2 {
			font-size: 63px;
			font-weight: normal;
			font-family: font(title);
			color: color(yellow, base);
			margin: 0;
			padding: 0;
			display: block;
			text-align: right;
			opacity: 0;
			visibility: hidden;
			transform: translateY(15px);
			transition: all 700ms;

			// Mobile
			@media (max-width: 1140px) {
				font-size: 50px;
			}
			@media (max-width: 767px) {
				font-size: 25px;
				text-align: center;
			}
			
			// & effect appear
			&.appear {
				opacity: 1;
				visibility: visible;
				transform: translateY(0px);
				transition-delay: 200ms;
			}
		}
		
		// Content
		.content {
			width: 100%;
			margin: 20px 0 0;
			padding: 0 0 0 80px;
			text-align: right;
			opacity: 0;
			visibility: hidden;
			transform: translateY(15px);
			transition: all 700ms;

			// Mobile
			@media (max-width: 767px) {
				padding: 0;
				text-align: center;
			}
			
			// & effect appear
			&.appear {
				opacity: 1;
				visibility: visible;
				transform: translateY(0px);
				transition-delay: 400ms;
			}

			// Paragraph
			p {
				font-size: 16px;
				font-weight: normal;
				color: color(yellow, light);
				margin: 0;
				padding: 0;
				line-height: 1.5;
			}

			.more {
				display: inline-block;
				margin-top: 10px;
				position: relative;
				overflow: hidden;

				// Link
				a {
					display: inline-block;
					font-size: 15px;
					font-weight: normal;
					font-family: font(open);
					margin: 0;
					padding: 10px;
					border: 1px solid color(yellow, light);
					color: color(yellow, light);
					text-transform: uppercase;
					position: relative;
					z-index: 2;
					transition: all 500ms;
				}

				// Hover
				&:hover {
					a {
						background-color: color(yellow, base);
						color: color(red);
					}
				}
			}
		}
	}

}