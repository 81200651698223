// Transition slider
@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
.fadeIn {
	animation-name: fadeIn;
	animation-fill-mode: forwards;
	transition: all 0ms;
}
@keyframes fadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}
.fadeOut {
	animation-name: fadeOut;
	animation-fill-mode: forwards;
	transition: all 0ms ;
}


@keyframes barPreloader {
	0% {
		transform: translateX(-100%);
	}
	100% {
		transform: translateX(100%);
	}
}
// Preloader
.preloader {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 65;
	background-color: color(red);
	pointer-events: none;
	transition: all 600ms;
	
	// Bar
	.bar {
		position: absolute;
		top: 50%;
		transform: translate(0,-50%);
		left: 0;
		right: 0;
		height: 4px;
		background-color: white;
		transition: all 600ms;
		transition-delay: 600ms;
		overflow: hidden;

		&::after {
			content: ' ';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 1;
			background-color: color(yellow, base);
			animation-name: barPreloader;
			animation-duration: 2000ms;
			animation-iteration-count: infinite;
		}
	}
	
	// & document ready
	&.get-out {
		transition-delay: 600ms;
		top: 50%;
		bottom: 50%;
		.bar {
			transform: translate(100%,-50%);
			transition-delay: 0ms;
		}
	}
}