// Hero page
.hero-pages {
	width: 100%;
	height: 425px;
	margin: 0;
	padding: 0;
	display: block;
	position: relative;
	overflow: hidden;

	// Mobile
	@media (max-width: 1023px) {
		height: 380px;
	}
	@media (max-width: 767px) {
		height: 300px;
	}

	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 3;
		background-image: linear-gradient(to bottom, rgba(0,0,0,0.7) 15%, transparent);
		
	}

	// Image
	.img {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		transition: all 500ms;
		transform: scale(1.05);
	}

	// Overlay
	.overlay {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 2;
		background-color: rgba(0,0,0,0.5);
	}

	// Flex-wrapper 
	.flex-wrapper {
		position: absolute;
		top: 70px;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 3;
		display: flex;
		align-items: center;
		h1 {
			font-size: 74px;
			font-weight: normal;
			font-family: font(title);
			color: white;
			margin: 0;
			padding: 0;
			display: block;
			text-align: center;

			// Mobile
			@media (max-width: 1140px) {
				font-size: 55px;
			}
			@media (max-width: 1023px) {
				font-size: 40px;
			}
			@media (max-width: 767px) {
				font-size: 25px;
			}

			&.yellow {
				color: color(yellow, base);
			}
		}
	}

}