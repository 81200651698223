#main-header {
	width: 100%;
	margin: 0;
	padding: 0;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 50;

	// Bar 
	.bar {
		width: 100%;
		margin: 0;
		padding: 5px 0;
		background-color: color(yellow, base);

		// Mobile
		@media (max-width: 767px) {
			display: none;
		}

		// Flex-wrapper
		.flex-wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		// Date
		.date {
			display: flex;
			align-items: center;

			// Icon
			.icon {
				font-size: 	15px;
				margin-right: 15px;
				svg {
					color: color(dark) !important;
				}
			}

			// Time
			.time {
				font-size: 14px;
				font-weight: normal;
				color: color(dark);
				margin: 0;
				padding: 0;
				strong {
					font-weight: 500;
				}
			}
		}

		// Right
		.right {
			display: flex ;
			align-items: center;

			// Phone
			.phone {

				// After ( | )
				&::after {
					content: ' | ';
					position: relative;
					font-size: 16px;
					font-weight: normal;
					color: color(dark);
					margin: 0 15px 0 10px;
					padding: 0;
				}

				// Text
				span {
					font-size: 14px;
					font-weight: normal;
					color: color(dark);
					margin: 0;
					padding: 0;
				}
			}

			// Social
			ul {
				margin: 0;
				padding: 0;
				li {
					display: inline-block;
					a {
						display: block;
						margin: 0;
						padding: 0;
						width: 20px;
						height: 20px;
						background-color: color(dark);
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 12px;
						transition: all 500ms;
						svg {
							color: color(yellow, base);
							transition: all 500ms;
						}
					}

					// Hover
					&:hover {
						a {
							background-color: color(yellow, base);
							svg {
								color: color(dark);
							}
						}
					}
				}
			}
		}
	}

	// Header
	.header {
		width: 100%;
		margin: 0;
		padding: 20px 0 0;

		// Flex wrapper
		.flex-wrapper {
			width: 100%;
			margin: 0;
			padding: 0;
			display: flex;
			align-items: center;
			justify-content: space-between;

			// Logo
			.logo {
				margin: 0;
				padding: 0;

				// Mobile
				@media (max-width: 1023px) {
					width: 150px;
					img {
						width: 100%;
					}
				}
			}

			// Navigation
			.nav{
				margin: 0;
				padding: 0;

				// Mobile
				@media (max-width: 1023px) {
					display: none;
				}

				ul {
					margin: 0;
					padding: 0;
					li {
						position: relative;
						display: inline-block;
						margin-right: 42px;

						// Mobile
						@media (max-width: 1280px) {
							margin-right: 30px;
						}
						@media (max-width: 1023px) {
							margin-right: 5px;
						}

						&:last-child {
							margin: 0;
						}

						a {
							font-size: 15px;
							font-weight: 500;
							color: white;
							margin: 0;
							padding: 8px 15px;
							display: block;
							position: relative;
							z-index: 2;
							transition: all 500ms;
						}

						// Submenu 
						ul {
							position: absolute;
							top: calc(100% + 5px);
							left: 0;
							right: 0;
							padding: 0;
							background-color: color(yellow, base);
							opacity: 0;
							transition: all 500ms;
							li {
								display: block;
								text-align: center;
								margin: 0;
								padding: 0;

								a {
									color: white;
									padding: 10px 0;

									&:hover {
										color: black;
									}
								}
							}
						}

						&:hover {
							ul {
								top: 100%;
								opacity: 1;
							}
						}
						

						// Hover
						&:hover,
						&.active {
							a {
								background-color: color(yellow, base);
							}
						}

						// Promocoes
						&.filled {
							background-color: color(red);


							a {
								padding: 5px 9px;
								color: color(yellow, base);
							}

							&:hover,
							&.active {
								a {
									background-color: color(yellow, base);
									color: white;
								}
							}

							.count {
								position: absolute;
								top: 0;
								right: 0;
								transform: translate(50%,-50%);
								width: 20px;
								height: 20px;
								display: flex;
								align-items: center;
								justify-content: center;
								font-size: 11px;
								font-family: font(open);
								font-weight: normal;
								color: color(yellow, base);
								background-color: color(red);
								border-radius: 50%;
								overflow: hidden;
								z-index: 5;
							}
						}
					}
				}
			}

			// Menu mobile
			.menu-mobile {

				svg {
					fill: white;
				}
				
				// Mobile
				@media (min-width: 1024px) {
					display: none;
				}
			}
		}
	}

	// & single page
	&.single {
		position: relative;
		.header {
			background-image: url('../images/bg-header.jpg');
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			padding: 20px 0;
		}
	}
}

.mobile-navigation {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0.7);
    z-index: 58;
    opacity: 0;
    visibility: hidden;
    transition: all 500ms;
    .wrapper {
        background-color: white;
        width: 70%;
        position: fixed;
        top: 0;
        bottom: 0;
        left: -100%;
        transition: all 500ms;
        padding: 30px 0;
        overflow-y: scroll;
        overflow-x: hidden;

        // Ul navigation
        nav ul {
        	margin: 0 0 20px;
            li {
                padding:20px 0 15px 30px;
                a {

			        font-size: 17px;
					font-weight: 500;
					color: color(red);
					margin: 0;
					padding: 0;
					display: block;
					position: relative;
					z-index: 2;
					transition: all 500ms;
					text-transform: uppercase;

					span {
						color: black;
						font-family: font(open);
					}
                }

                ul {
					width: 100%;
					margin: 30px 0 0 25px;
					padding: 0;

					li {
						width: auto;
						margin: 0 0 25px;
						padding: 0;

						&:last-child {
							margin: 0;
						}
					}
				}
            }
        }

        // Reserva
        .reserva {
        	width: 100%;
        	margin: 0 0 20px;
        	padding: 0 30px;
        	span {
        		font-size: 17px;
				font-weight: 500;
				color: color(red);
				margin: 0;
				padding: 0;
				display: block;
				position: relative;
				z-index: 2;
				transition: all 500ms;
				text-transform: uppercase;
        	}
        }

        // Date
		.date {
			display: flex;
			align-items: center;
			margin: 30px 0;
			padding: 0 30px;

			// Icon
			.icon {
				font-size: 	15px;
				margin-right: 15px;
				svg {
					color: color(dark) !important;
				}
			}

			// Time
			.time {
				font-size: 14px;
				font-weight: normal;
				color: color(dark);
				margin: 0;
				padding: 0;
				line-height: 1.4;
				strong {
					font-weight: 500;
				}
			}
		}

		// Phone
		.phone {
			display: block;
			margin: 0;
			padding: 0 30px;

			// Text
			span {
				font-size: 14px;
				font-weight: normal;
				color: color(dark);
				margin: 0;
				padding: 0;
			}
		}

		// Right
		.social {
			display: flex ;
			align-items: center;
			margin-top: 30px;
			padding: 0 30px;


			// Social
			ul {
				margin: 0;
				padding: 0;
				li {
					display: inline-block;
					a {
						display: block;
						margin: 0 10px 0 0;
						padding: 0;
						width: 20px;
						height: 20px;
						background-color: color(dark);
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 12px;
						transition: all 500ms;
						svg {
							color: color(yellow, base);
							transition: all 500ms;
						}
					}

					// Hover
					&:hover {
						a {
							background-color: color(yellow, base);
							svg {
								color: color(dark);
							}
						}
					}
				}
			}
		}

        // Mobile
        @media (max-width: 375px) {
            width: 85%;
        }
        @media (max-width: 320px) {
            width: 90%;
        }
    }

    // & active class
    &.active {
        opacity: 1;
        visibility: visible;
        .wrapper {
            left: 0;
        }
    }
}