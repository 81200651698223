.page-cardapio {
	width: 100%;
	max-width: 100%;
	overflow: hidden;
	margin: 0;
	padding: 0;
	background-color: color(bg);

	// Cardapio
	#cardapio {
		width: 100%;
		margin: 0;
		padding: 10px 0 70px;

		// Container
		.container {
			position: relative;
		}

		// Navigation
		.navigation	{
			width: 100%;
			padding: 10px 0;
			position: relative;
			background-color: color(yellow, base);

			// Mobile
			@media (max-width: 1130px) {
				width: 900px;
				margin: 40px auto 0;
			}
			@media (max-width: 1023px) {
				width: 600px;
			}
			@media (max-width: 767px) {
				width: 300px;
			}
			@media (max-width: 425px) {
				width: 230px;
			}
			
			.owl-item {
				outline: none;
				cursor: pointer;

				// Item
				.item {
					outline: none;
					position: relative;
					padding: 4px 0 0;
					text-align: center;

					span {
						font-size: 15px;
						font-weight: 400;
						color: black;
						margin: 0;
						padding: 0;
						text-transform: uppercase;
						transition: all 500ms;
						opacity: 0.6;
						line-height: 1.4;
					}

					&.active,
					&:hover {
						span {
							opacity: 1;
						}
					}
				}

				// Item active
			}

			// Arrows
			.arrows {
				position: absolute;
				top: 50%;
				left: -35px;
				right: -35px;
				z-index: 3;
				transform: translateY(-50%);
				pointer-events: none;
				.flex-wrapper {
					display: flex;
					align-items: center;
					justify-content: space-between;
					.arrow {
						pointer-events: initial;
						cursor: pointer;
						font-size: 44px;
						font-family: font(open);
						font-weight: normal;
						color: color(red);
					}
				}
			}
		}

		// Tabs
		.tabs {
			width: 100%;
			margin: 50px 0 0;
			padding: 0;
			outline: none;
			transition: all 500ms;

			.tab-content {
				width: 100%;
				margin: 0;
				padding: 0;
				opacity: 0;
				visibility: hidden;
				outline: none;
				position: absolute;
				top: 0;
				right: 0;
				left: 0;
				transition: all 500ms;
				transform: translateY(15px);
				
				// Tab active
				&.active {
					transform: translateY(0px);
					opacity: 1;
					visibility: visible;
					transition-delay: 550ms;
				}


				// Content 
				.content {
					width: 100%;
					margin: 0;
					padding: 0;
					ul {
						width: 100%;
						margin: 0;
						padding: 0;
						column-count: 3;
						column-gap: 70px;
						column-rule: 70px;

						// Mobile
						@media (max-width: 1023px) {
							column-count: 2;
							column-gap: 30px;
							column-rule: 30px;
							padding: 0 15px;
						}
						@media (max-width: 767px) {
							column-count: 1;
							column-gap: 0px;
							column-rule: 0px;
						}


						li {
							display: inline-block;
							margin-bottom: 25px;
							
							// Title
							.title {
								font-size: 16px;
								font-weight: 500;
								color: black;
								margin: 0;
								padding: 0;
							}

							// Content
							p {
								font-size: 16px;
								font-weight: 300;
								color: black;
								margin: 0;
								padding: 0;
								line-height: 1.7;
							}
						}
					}
				}

			}
		}
	}
}