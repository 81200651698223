.page-sobre {
	width: 100%;
	max-width: 100%;
	margin: 0;
	padding: 0;
	position: relative;
	z-index: 2;

	// Sobre
	#sobre {
		width: 100%;
		margin: 0;
		padding: 60px 0 65px;
		background-color: color(bg);
		max-width: 100%;
		overflow: hidden;

		// Image
		.img {
			width: 100%;
			margin: 0;
			padding: 0;
			img {
				max-width: 100%;
			}
		}

		// Content
		.wrapper {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			.content {
				width: 100%;
				margin: 0;
				padding: 0;
				opacity: 0;
				visibility: hidden;
				transform: translateY(15px);
				transition: all 500ms;
				
				// & effect appear
				&.appear {
					opacity: 1;
					visibility: visible;
					transform: translateY(0);
				}


				img {
					margin-bottom: 15px;

					// Mobile
					@media (max-width: 767px) {
						width: 100%;
						max-width: 300px;
						margin-top: 30px;
					}
				}
				p {
					font-size: 16px;
					font-weight: 300;
					color: black;
					margin: 0;
					padding: 0;
					line-height: 1.7;
				}
			}
		}
	}

	// Gallery
	#gallery {
		width: 100%;
		height: 410px;
		margin: 0;
		padding: 0;
		position: relative;
		max-width: 100%;
		overflow: hidden;

		// Mobile
		@media (max-width: 1750px) {
			height: 300px;
		}
		@media (max-width: 1450px) {
			height: 250px;
		}
		@media (max-width: 1279px) {
			height: 330px;
		}
		@media (max-width: 375px) {
			height: 250px;
		}

		// Slider
		.slider {
			width: 100%;
			margin: 0;
			padding: 0;
			
			.item {
				width: 100%;
				height: 410px;
				margin: 0;
				padding: 0;
				position: relative;

				// Mobile
				@media (max-width: 1750px) {
					height: 300px;
				}
				@media (max-width: 1450px) {
					height: 250px;
				}
				@media (max-width: 1279px) {
					height: 330px;
				}
				@media (max-width: 375px) {
					height: 250px;
				}
				

				// Image
				.img {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					z-index: 1;
					background-size: cover;
					background-position: center center;
					background-repeat: no-repeat;
				}
			}
		}

		// Arrows
		.arrows {
			position: absolute;
			top: 50%;
			left: 30px;
			right: 30px;
			z-index: 3;
			transform: translateY(-50%);
			pointer-events: none;
			.flex-wrapper {
				display: flex;
				align-items: center;
				justify-content: space-between;
				.arrow {
					pointer-events: initial;
					cursor: pointer;
					font-size: 44px;
					font-family: font(open);
					font-weight: normal;
					color: white;
					background-color: rgba(0,0,0,0.8);
					padding: 5px 15px;
				}
			}
		}
	}

	// Segredos
	#segredos {
		width: 100%;
		margin: 0;
		padding: 105px 0 120px;
		background-image: url('../images/bg-sobre.jpg');
		background-position: center center;
		background-size: cover;
		position: relative;
		z-index: 2;

		@media (max-width: 767px) {
			padding: 60px 0 65px;
		}

		// Background
		.bg {
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			background-image: url('../images/bg-segredos.png');
			background-repeat: no-repeat;
			background-position: bottom right;
			background-size: contain;
			z-index: 4;
			pointer-events: none;

			// Mobile
			@media (max-width: 1439px) {
				display: none;
			}
		}

		// Image
		.img {
			position: absolute;
			bottom: -64px;
			right: 0;
			z-index: 5;
			pointer-events: none;

			// Mobile
			@media (max-width: 1023px) {
				display: none;
			}
		}

		// Wrapper
		.wrapper {
			width: 100%;
			margin: 0;
			padding: 0;

			// Title
			h2 {
				font-size: 74px;
				font-weight: normal;
				font-family: font(title);
				color: white;
				margin: 0;
				padding: 0 50px 0 0;
				display: block;
				opacity: 0;
				visibility: hidden;
				transform: translateY(15px);
				transition: all 500ms;

				// Mobile
				@media (max-width: 1140px) {
					font-size: 60px;
				}
				@media (max-width: 768px) {
					font-size: 45px;
				}
				@media (max-width: 767px) {
					font-size: 25px;
					line-height: 1.3;
				}
				
				// & effect appear
				&.appear {
					opacity: 1;
					visibility: visible;
					transform: translateY(0);
				}
			}

			// Content
			.content {
				width: 100%;
				margin: 25px 0 0;
				padding: 0;
				opacity: 0;
				visibility: hidden;
				transform: translateY(15px);
				transition: all 500ms;
				
				// & effect appear
				&.appear {
					opacity: 1;
					visibility: visible;
					transform: translateY(0);
				}

				// Paragraph
				p {
					font-size: 16px;
					font-weight: 300;
					color: white;
					margin: 0;
					padding: 0;
					line-height: 1.7;
				}
			}
		}
	}
}