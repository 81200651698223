/** Colors list. */
$colors: (
    bg: #f0e3cc,
    yellow: (
        base: #f4ab1c,
        light: #e6a94e,
        dark: #a06806
    ),
    red: #8d1212,
    dark: #8a5c04,
    black: #161515
    // blue: (
    //     default: #00F,
    //     dark:    #005
    // )
);

/**
 * Returns a color code from its key.
 * 
 * @param  {string} $keys... Color key.
 * @return {string}          Color code.
 */
@function color($keys...) {
    $map: $colors;
    @each $key in $keys {
        $map: map-get($map, $key);
    }
    @return $map;
}


/** Font families list. */
$fonts: (
    main: "Raleway",
    open: "Open Sans",
    title: "Bernadette Rough"
    // content: "Fira"
);

/**
 * Returns a font family name from its category.
 * 
 * @param  {string} $font-category Font family category.
 * @return {string}                Font family name.
 */
@function font($font-category) {
    @return map-get($fonts, $font-category);
}

/** Font sizes list. */
$size: (
    // title:    4.5rem,
    // headline: 1.6rem,
    // content: (
    //     small:  1.4rem,
    //     medium: 2.4rem,
    //     large:  3.0rem
    // )
);

/**
 * Returns a font size from its category.
 * 
 * @param    {string} $categories... Font size category.
 * @return   {number}                Font size value in [rem].
 * @requires [:root] font-size to be defined.
 */
@function size($categories...) {
    $map: $size;
    @each $category in $categories {
        $map: map-get($map, $category);
    }
    @return $map;
}
