// Cta
.cta {
	position: fixed;
	z-index: 55;
	bottom: 0;
	right: 0;
	display: flex;
	transform: translateX(calc(100% - 70px));
	transition: all 700ms;
	pointer-events: none;

	@media (min-width: 768px) and (max-height: 665px) {
		top: 0;
		bottom: unset;
		position: absolute;
		align-items: flex-end;
	}

	// Mobile
	@media (max-width: 1140px) {
		top: unset;
		bottom: 0;
		align-items: flex-end;
	}
	@media (max-width: 1023px) {
		display: none;
	}
	
	// Wrapper
	.wrapper {
		width: 70px;
		height: 210px;
		padding: 58px 0 10px;
		background-color: color(red);
		cursor: pointer;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		pointer-events: initial;

		@media (min-width: 768px) and (max-height: 665px) {
			margin-bottom: 100px;
		}

		// Mobile
		@media (max-width: 767px) {
			height: 150px;
		}


		// Content
		.content {
			transform: rotate(-90deg);
			position: relative;


			// Open
			.open {
				font-size: 12px;
				font-weight: normal;
				color: color(yellow, base);
				margin: 0;
				padding: 0;
				white-space: nowrap;
				text-transform: uppercase;
				strong {
					font-size: 19px;
					letter-spacing: 0.5px;
				}
			}

			// Close
			.close {
				font-size: 15px;
				font-weight: normal;
				color: color(yellow, base);
				margin: 0;
				padding: 0;
				text-transform: uppercase;
				white-space: nowrap;
				display: none;
				svg {
					color: color(yellow, base);
					font-size: 17px;
					margin-left: 15px;
				}
			}

		}

		// Icon
		.icon {
			padding-top: 10px;
			border-top: 1px solid #6d0e0e;
			width: 50px;
			text-align: center;
			
			// Mobile
			@media (max-width: 767px) {
				display: none;
			}

			svg {
				font-size: 30px;
				color: color(yellow, base);
			}
		}
	}

	// Right 
	.right {
		width: 500px;
		background-color: white;
		padding: 15px 40px 40px;
		text-align: center;
		pointer-events: initial;

		// Mobile
		@media (max-width: 1140px) {
			padding-bottom: 25px;
		}
		@media (max-width: 767px) {
			display: none;
		}

		// Title
		.title {
			width: 100%;
			display: block;
			margin: 0;
			padding: 0;
			text-align: center;
			font-size: 17px;
			font-weight: 500;
			color: black;
			text-transform: uppercase;
		}

		// Subtitle
		.subtitle {
			width: 100%;
			margin: 15px 0 0;
			padding: 0;
			font: 15px;
			font-weight: 500;
			color: black;
			text-align: center;

			// Mobile
			@media (max-width: 1140px) {
				margin-bottom: 15px;
			}
		}

		// Form
		.form {
			width: 100%;
			margin: 0 auto;
			padding: 0;
			display: block;
			text-align: center;
			
			span {
				font-size: 16px;
				font-weight: normal;
				display: block;
				color: color(red);
				margin: 20px 0 5px;
				padding: 0;
				text-align: left;

				// Mobile
				@media (max-width: 1140px) {
					margin-top: 10px;
				}
			}

			input {
				width: 100%;
				margin: 0;
				padding: 0;
				border: none;
				outline: none;
				background-color: color(bg);
				height: 45px;
				font-size: 16px;
				font-weight: normal;
				color: color(red);
				padding: 0 10px;
			}

			textarea {
				width: 100%;
				min-height: 90px;
				max-height: 105px;
				min-width: 100%;
				max-width: 100%;
				margin: 0;
				padding: 0;
				border: none;
				outline: none;
				background-color: color(bg);
				font-size: 16px;
				font-weight: normal;
				color: color(red);
				padding: 10px;
			}

			.submit {
				width: 165px;
				display: inline-block;
				margin: 40px 0 0;
				padding: 0;
				text-align: center;
				position: relative;
				overflow: hidden;
				
				button {
					width: 165px;
					display: inline-block;
					font-size: 15px;
					font-weight: normal;
					font-family: font(open);
					margin: 0;
					padding: 8px 0px;
					border: 1px solid color(red);
					color: color(red);
					text-transform: uppercase;
					outline: none;
					position: relative;
					z-index: 2;
					transition: all 500ms;
				}

				// Hover
				&:hover {
					button {
						background-color: color(red);
						color: color(yellow, base);
					}
				}
			}
		}
	}
	
	@media (min-width: 768px) {
		&.active {
			transform: translateX(0);
			.wrapper {
				padding: 68px 0 10px;
				.open {
					display: none;
				}

				.close {
					display: block;
				}
			}
		}
	}

	@media (max-width: 1024px) {

		&.scroll {
			opacity: 0;
			pointer-events: none;
			.wrapper {
				pointer-events: none;
			}
		}
	}
	
}

// Mobile cta 
.modal-cta {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 60;
	background-color: white;
	padding: 30px 10px;
	overflow-y: scroll;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	visibility: hidden;
	transition: all 600ms;
	pointer-events: none;

	&.active {
		opacity: 1;
		visibility: visible;
		pointer-events: initial;
	}

	@media (min-width: 768px) {
		display: none;
	}

	.wrapper {
		width: 100%;
		height: 100%;
		overflow-y: scroll;
		overflow-x: hidden;
	}

	// Close
	.close {
		position: absolute;
		top: 5px;
		right: 5px;
		font-size: 15px;
		font-weight: normal;
		color: color(red);
		margin: 0;
		padding: 0;
		text-transform: uppercase;
		white-space: nowrap;
		svg {
			color: color(red);
			font-size: 15px;
			margin-left: 10px;
		}
	}

	// Title
	.title {
		width: 100%;
		display: block;
		margin: 0;
		padding: 0;
		text-align: center;
		font-size: 17px;
		font-weight: 500;
		color: black;
		text-transform: uppercase;
	}

	// Subtitle
	.subtitle {
		width: 100%;
		margin: 15px 0 0;
		padding: 0;
		font: 15px;
		font-weight: 500;
		color: black;
		text-align: center;

		// Mobile
		@media (max-width: 1140px) {
			margin-bottom: 15px;
		}
	}

	// Form
	.form {
		width: 100%;
		margin: 0 auto;
		padding: 0;
		display: block;
		text-align: center;
		
		span {
			font-size: 14px;
			font-weight: normal;
			display: block;
			color: color(red);
			margin: 20px 0 5px;
			padding: 0;
			text-align: left;

			@media (max-width: 370px) {
				font-size: 13px;
			}
		}

		input {
			width: 100%;
			margin: 0;
			padding: 0;
			border: none;
			outline: none;
			background-color: color(bg);
			height: 45px;
			font-size: 16px;
			font-weight: normal;
			color: color(red);
			padding: 0 10px;
		}

		textarea {
			width: 100%;
			min-height: 90px;
			max-height: 105px;
			min-width: 100%;
			max-width: 100%;
			margin: 0;
			padding: 0;
			border: none;
			outline: none;
			background-color: color(bg);
			font-size: 16px;
			font-weight: normal;
			color: color(red);
			padding: 10px;
		}

		.submit {
			width: 165px;
			display: inline-block;
			margin: 40px 0 0;
			padding: 0;
			text-align: center;
			position: relative;
			overflow: hidden;
			
			button {
				width: 165px;
				display: inline-block;
				font-size: 15px;
				font-weight: normal;
				font-family: font(open);
				margin: 0;
				padding: 8px 0px;
				border: 1px solid color(red);
				color: color(red);
				text-transform: uppercase;
				outline: none;
				position: relative;
				z-index: 2;
				transition: all 500ms;
			}

			// Hover
			&:hover {
				button {
					background-color: color(red);
					color: color(yellow, base);
				}
			}
		}
	}
}