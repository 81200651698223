.page-eventos {
	width: 100%;
	max-width: 100%;
	overflow: hidden;
	margin: 0;
	padding: 0;

	// Evento
	#evento {
		width: 100%;
		margin: 0;
		padding: 95px 0 150px;
		background-image: url('../images/bg-eventos.jpg');
		background-size: cover;
		background-repeat: no-repeat;
		position: relative;

		// Mobile 
		@media (max-width: 1023px) {
			padding: 60px 0 80px;
		}
		
		// Image
		.img {
			position: absolute;
			bottom: -30px;
			right: 0;
			z-index: 2;
			pointer-events: none;

			// Mobile 
			@media (max-width: 1600px) {
				img {
					width: 600px;
				}
			}
			@media (max-width: 1023px) {
				display: none;
			}
		}

		// Title
		h2 {
			font-size: 63px;
			font-weight: normal;
			font-family: font(title);
			color: color(yellow, base);
			margin: 0;
			padding: 0 40px 0 0;
			display: block;
			opacity: 0;
			visibility: hidden;
			transform: translateY(15px);
			transition: all 500ms;

			// Mobile 
			@media (max-width: 1023px) {
				font-size: 40px;
			}
			@media (max-width: 767px) {
				font-size: 25px;
			}
			
			// & effect appear
			&.appear {
				opacity: 1;
				visibility: visible;
				transform: translateY(0);
			}
		}
		
		// Content
		.wrapper {
			width: 100%;
			margin: 0;
			padding: 0;
			.content {
				width: 100%;
				margin: 20px 0 0;
				padding: 0;
				opacity: 0;
				visibility: hidden;
				transform: translateY(15px);
				transition: all 500ms;
				
				// & effect appear
				&.appear {
					opacity: 1;
					visibility: visible;
					transform: translateY(0);
				}

				// Paragraph
				p {
					font-size: 16px;
					font-weight: normal;
					color: color(yellow, light);
					margin: 0;
					padding: 0;
					line-height: 1.5;
				}

				.more {
					display: inline-block;
					margin-top: 10px;
					position: relative;
					overflow: hidden;

					a {
						display: inline-block;
						font-size: 15px;
						font-weight: normal;
						font-family: font(open);
						margin: 0;
						padding: 10px;
						border: 1px solid color(yellow, light);
						color: color(yellow, light);
						text-transform: uppercase;
						position: relative;
						z-index: 2;
						transition: all 500ms;
					}

					// Hover
					&:hover {
						a {
							background-color: color(yellow, base);
							color: color(red);
						}
					}
				}
			}
		}
	}
	
}