.page-contato {
	width: 100%;
	max-width: 100%;
	overflow: hidden;
	margin: 0;
	padding: 0;

	// Contato
	#contato {
		width: 100%;
		margin: 0;
		padding: 70px 0 0;
		background-color: color(yellow, base);
			
		// Wrapper
		.wrapper {
			width: 100%;
			margin: 0;
			padding: 0;
			text-align: center;

			h1 {
				font-size: 74px;
				font-weight: normal;
				font-family: font(title);
				color: color(red);
				margin: 0;
				padding: 0;
				display: block;

				// Mobile
				@media (max-width: 1140px) {
					font-size: 65px;
				}
				@media (max-width: 768px) {
					font-size: 55px;
				}
				@media (max-width: 767px) {
					font-size: 35px;
					line-height: 1.3;
				}
			}

			.content {
				margin-top: 5px;
				p {
					font-size: 16px;
					font-weight: normal;
					color: color(red);
					margin: 0;
					padding: 0;
					line-height: 1.7;
				}
			}
		}

		// Form
		.form {
			width: 675px;
			margin: 0 auto;
			padding: 40px 0 50px;
			display: block;

			@media (max-width: 1140px) {
				width: 100%;
			}

			form {
				text-align: center;
			}
			
			span {
				font-size: 16px;
				font-weight: normal;
				display: block;
				color: color(red);
				margin: 20px 0 5px;
				padding: 0;
				text-align: left;
			}

			input {
				width: 100%;
				margin: 0;
				padding: 0;
				border: none;
				outline: none;
				background-color: white;
				height: 45px;
				font-size: 16px;
				font-weight: normal;
				color: color(red);
				padding: 0 10px;
			}

			textarea {
				width: 100%;
				min-height: 165px;
				max-height: 195px;
				max-width: 100%;
				margin: 0;
				padding: 0;
				border: none;
				outline: none;
				background-color: white;
				font-size: 16px;
				font-weight: normal;
				color: color(red);
				padding: 10px;
			}

			.submit {
				width: auto;
				display: inline-block;
				margin-top: 40px;
				padding: 0;
				text-align: center;
				position: relative;
				overflow: hidden;
				
				button {
					width: 165px;
					display: inline-block;
					font-size: 15px;
					font-weight: normal;
					font-family: font(open);
					margin: 0;
					padding: 8px 0px;
					border: 1px solid color(red);
					color: color(red);
					text-transform: uppercase;
					outline: none;
					position: relative;
					z-index: 2;
					transition: all 500ms;
				}

				// Hover
				&:hover {
					button {
						background-color: color(red);
						color: color(yellow, base);
					}
				}
			}
		}

		.content-mobile {
			width: 100%;
			margin: 0;
			padding: 25px;
			background-color: color(yellow, base);
			text-align: center;

			// Mobile
			@media (min-width: 768px) {
				display: none;
			}

			// Title
			.title {
				font-size: 30px;
				font-weight: 500;
				margin: 0;
				padding: 0;
				color: #391109;
			}

			// Address
			.address {
				width: 100%;
				margin: 3px 0 7px;
				padding: 0;
				p {
					font-size: 14px;
					font-weight: normal;
					color: black;
					margin: 0;
					padding: 0;
					line-height: 1.4;
				}
			}

			// Phone
			.phone {
				width: 100%;
				margin: 0;
				padding: 0;
				font-size: 18px;
				font-weight: 500;
				color: #391109;
				margin: 0;
				padding: 0;
			}
		}

		// Map 
		.map {
			width: 100%;
			height: 400px;
			position: relative;
			display: flex;
			align-items: center;

			// Info
			.wrapper {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: 2;
				display: flex;
				align-items: center;
				pointer-events: none;

				@media (max-width: 767px) {
					display: none;
				}
				.container {
					position: relative;
					display: flex;
					justify-content: flex-end;
					.content {
						width: 305px;
						margin: 0;
						padding: 25px;
						background-color: color(yellow, base);
						text-align: right;

						@media (max-width: 1023px) {
							width: 255px;
						}

						// Title
						.title {
							font-size: 30px;
							font-weight: 500;
							margin: 0;
							padding: 0;
							color: #391109;
						}

						// Address
						.address {
							width: 100%;
							margin: 3px 0 7px;
							padding: 0;
							p {
								font-size: 14px;
								font-weight: normal;
								color: black;
								margin: 0;
								padding: 0;
								line-height: 1.4;
							}
						}

						// Phone
						.phone {
							width: 100%;
							margin: 0;
							padding: 0;
							font-size: 18px;
							font-weight: 500;
							color: #391109;
							margin: 0;
							padding: 0;
						}
					}
				}
			}
		}
	}

}